@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800');
body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --accent: #8855ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* span {
  font-family: Montserrat !important;
  font-size: 16px !important;
} */

.oferta p span {
  font-family: Montserrat !important;
  font-size: 16px !important;
}

ul, ol {
  list-style: initial; /* Muestra las viñetas o números predeterminados */
  margin: 0 0 1em 1.5em; /* Ajusta el espaciado de las listas si es necesario */
  padding: 0;
}


