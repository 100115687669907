

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

iframe {
  min-height: 100vh;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.underline {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--accent);
}

img {
  margin-left: 30px;
  margin-bottom: 10px
}

input {
  font-family: Montserrat;
}

.segona-pagina p {
  margin: 0 !important;
  text-align: left !important;
}

.segona-pagina span {
  color: #575756 !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  text-align: left !important;
}

.tercera-pagina p {
  margin: 0 !important;
  text-align: left !important;
}

.tercera-pagina span {
  color: #575756 !important;
  font-size: 12px !important;
  text-align: left !important;
}

.quarta-pagina p {
  margin: 0 !important;
  text-align: left !important;
}

.quarta-pagina span {
  color: #575756 !important;
  font-size: 12px !important;
  text-align: left !important;
}

ul {
  list-style: none;
  /* height: 60px; */
  display: block;
  outline: none;
}

.li-first {
  display: block;
  width: calc(100% / 2 - 30px);
  float: left;
  text-align: center;
  background: #d1185a;
  margin-right: 45px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 0;
  text-transform: uppercase;
}

.li-second {
  display: block;
  width: calc(100% / 4 - 30px);
  float: left;
  text-align: center;
  background: #d1185a;
  margin-right: 45px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 0;
  text-transform: uppercase;
}

.li-third {
  display: block;
  width: calc(100% / 4 - 30px);
  float: left;
  text-align: center;
  background: #d1185a;
  margin-right: 45px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 0;
  text-transform: uppercase;
  margin-right: 0;
}

.printableIcon {
  position: absolute;
  right: 0;
  background: #fff;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: #575756;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}